import React from 'react'

import SponsorLevel from './SponsorLevel'
import * as s from './sponsors.scss'

const Sponsors = ({ input }) => {
    return (
        <section className={s.sponsors}>
            <h2>{input.primary.section_title.text}</h2>
            <div className={s.sponsors__wrapper}>
                {input.primary.which_city.document.data.body.map(
                    (level, index) => (
                        <SponsorLevel key={index} row={level} index={index} />
                    )
                )}
            </div>
        </section>
    )
}

export default Sponsors
