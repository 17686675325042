import React from 'react'

import * as s from './silent-auctions.scss'

const SilentAuctions = ({ input }) => {
    return (
        <section className={s.auctions}>
            <h2>{input.primary.headline1.text}</h2>
            {input.primary.copy.html && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: input.primary.copy.html,
                    }}
                ></div>
            )}
            <div className={s.auctions__wrapper}>
                {input.primary.which_city.document !== null &&
                    input.primary.which_city.document.data.body.map(
                        (item, index) => (
                            <div
                                key={index}
                                className={s.card}
                                dangerouslySetInnerHTML={{
                                    __html: item.primary.item.html,
                                }}
                            ></div>
                        )
                    )}
            </div>
        </section>
    )
}

export default SilentAuctions
