import React from 'react'
import Seo from '../components/SEO'
import SliceZone from '../components/SliceZone'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
    const doc = data.allPrismicPage.nodes[0].data
    return (
        <>
            <Seo title={doc.title.text} />
            <SliceZone allSlices={doc.body} />
        </>
    )
}

export default Page

export const pageQuery = graphql`
    query($id: String!) {
        allPrismicPage(filter: { id: { eq: $id } }) {
            nodes {
                data {
                    title {
                        text
                    }
                    body {
                        ... on PrismicPageBodyHero {
                            id
                            slice_type
                            primary {
                                headline {
                                    text
                                }
                                subheadline {
                                    text
                                }
                                purchase_link {
                                    url
                                }
                                image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicPageBody5050Section {
                            id
                            slice_type
                            primary {
                                content {
                                    html
                                }
                                image_side
                                image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicPageBodyWineWhiskeyBreweriesSection {
                            id
                            slice_type
                            primary {
                                headline1 {
                                    text
                                }
                                city_wwb_list {
                                    document {
                                        ... on PrismicWwbList {
                                            id
                                            data {
                                                body {
                                                    ... on PrismicWwbListBodyList {
                                                        id
                                                        primary {
                                                            list {
                                                                html
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                background_image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicPageBodyLiveAuctions {
                            id
                            slice_type
                            primary {
                                headline1 {
                                    text
                                }
                                which_city {
                                    document {
                                        ... on PrismicLiveAuctions {
                                            id
                                            data {
                                                body {
                                                    ... on PrismicLiveAuctionsBodyItem {
                                                        id
                                                        primary {
                                                            item_title {
                                                                html
                                                            }
                                                            item_description {
                                                                html
                                                            }
                                                            item_image {
                                                                localFile {
                                                                    childImageSharp {
                                                                        fluid {
                                                                            ...GatsbyImageSharpFluid
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicPageBodySilentAuctions {
                            id
                            slice_type
                            primary {
                                headline1 {
                                    text
                                }
                                copy {
                                    html
                                }
                                which_city {
                                    document {
                                        ... on PrismicSilentAuctions {
                                            id
                                            data {
                                                body {
                                                    ... on PrismicSilentAuctionsBodyItem {
                                                        id
                                                        primary {
                                                            item {
                                                                html
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        ... on PrismicPageBodySponsorsSection {
                            id
                            slice_type
                            primary {
                                section_title {
                                    text
                                }
                                which_city {
                                    document {
                                        ... on PrismicSponsors {
                                            id
                                            data {
                                                body {
                                                    ... on PrismicSponsorsBodySponsorshipLevel {
                                                        id
                                                        primary {
                                                            sponsor_level {
                                                                text
                                                            }
                                                        }
                                                        items {
                                                            image {
                                                                localFile {
                                                                    childImageSharp {
                                                                        fluid {
                                                                            ...GatsbyImageSharpFluid
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            name_of_person_instead_of_logo {
                                                                text
                                                            }
                                                            url {
                                                                text
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
