import React from 'react'

import Img from 'gatsby-image'
import * as s from './wwb.scss'

const WwbSection = ({ input }) => {
    return (
        <section className={s.wwb}>
            <div className={`hero-image ${s.wwb__img}`}>
                <Img
                    fluid={
                        input.primary.background_image.localFile.childImageSharp
                            .fluid
                    }
                    style={{ width: `100%`, height: `100%` }}
                />
            </div>
            <div className={s.wwb__wrapper}>
                <h2>{input.primary.headline1.text}</h2>
                <div className={s.wwb__container}>
                    {input.primary.city_wwb_list.document.data.body.map(
                        (list, index) => (
                            <div
                                key={index}
                                className={s.listWrapper}
                                dangerouslySetInnerHTML={{
                                    __html: list.primary.list.html,
                                }}
                            ></div>
                        )
                    )}
                </div>
            </div>
        </section>
    )
}

export default WwbSection
