import React from 'react'

import Img from 'gatsby-image'

import * as s from './hero.scss'

const Hero = ({ input }) => {
    return (
        <section className={s.hero}>
            <div className={`hero-image ${s.hero__image}`}>
                <Img
                    fluid={input.primary.image.localFile.childImageSharp.fluid}
                    style={{ width: `100%`, height: `100%` }}
                />
            </div>
            <div className={s.hero__wrapper}>
                <h1>{input.primary.headline.text}</h1>
                <h2>{input.primary.subheadline.text}</h2>
                <a
                    href={input.primary.purchase_link.url}
                    className={`btn ${s.hero__btn}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Purchase Your Tickets Now
                </a>
            </div>
        </section>
    )
}

export default Hero
