import React from 'react'

import Img from 'gatsby-image'

import * as s from './auction-card.scss'

const AuctionCard = ({ item }) => {
    const card = item.primary
    return (
        <article className={s.card}>
            <div className={s.card__img}>
                <Img
                    fluid={card.item_image.localFile.childImageSharp.fluid}
                    style={{ width: `100%`, height: `100%` }}
                />
            </div>
            <div className={s.card__body}>
                <div
                    className={s.card__title}
                    dangerouslySetInnerHTML={{ __html: card.item_title.html }}
                ></div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: card.item_description.html,
                    }}
                ></div>
            </div>
        </article>
    )
}

export default AuctionCard
