import React from 'react'

import AuctionCard from './AuctionCard'
import * as s from './live-auctions.scss'

const LiveAuctions = ({ input }) => {
    return (
        <section className={s.auctions}>
            <h2>{input.primary.headline1.text}</h2>
            <div className={s.auctions__wrapper}>
                {input.primary.which_city.document.data.body.map(
                    (item, index) => (
                        <AuctionCard key={index} item={item} />
                    )
                )}
            </div>
        </section>
    )
}

export default LiveAuctions
