import React from 'react'

import Img from 'gatsby-image'
import * as s from './sponsor-level.scss'

const SponsorLevel = ({ row, index }) => {
    return (
        <div className={s.level} data-index={index}>
            <h3>{row.primary.sponsor_level.text}</h3>
            <div className={s.level__wrapper}>
                {row.items.map((sponsor, index) => (
                    <div
                        key={index}
                        className={s.sponsor}
                        data-sponsor={
                            sponsor.image.localFile ? 'image' : 'name'
                        }
                    >
                        {sponsor.image.localFile && (
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={sponsor.url.text}
                            >
                                <Img
                                    fluid={
                                        sponsor.image.localFile.childImageSharp
                                            .fluid
                                    }
                                    style={{ width: `100%`, height: `100%` }}
                                    imgStyle={{ objectFit: `contain` }}
                                />
                            </a>
                        )}
                        {sponsor.name_of_person_instead_of_logo && (
                            <h5>
                                {sponsor.name_of_person_instead_of_logo.text}
                            </h5>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SponsorLevel
