import React from 'react'
import PropTypes from 'prop-types'
import {
    Hero,
    SplitSection,
    WwbSection,
    LiveAuctions,
    SilentAuctions,
    Sponsors,
} from '../slices'

const SliceZone = ({ allSlices }) => {
    return allSlices.map((s, index) => {
        switch (s.slice_type) {
            // These are the API IDs of the slices
            case 'hero':
                return <Hero key={index} input={s} />
            case '50_50_section':
                return <SplitSection key={index} input={s} />
            case 'wine__whiskey__breweries_section':
                return <WwbSection key={index} input={s} />
            case 'live_auctions':
                return <LiveAuctions key={index} input={s} />
            case 'silent_auctions':
                return <SilentAuctions key={index} input={s} />
            case 'sponsors_section':
                return <Sponsors key={index} input={s} />
            default:
                return null
        }
    })
}

SliceZone.propTypes = {
    allSlices: PropTypes.array.isRequired,
}

export default SliceZone
