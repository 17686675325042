import React from 'react'

import Img from 'gatsby-image'

import * as s from './split-section.scss'

const SplitSection = ({ input }) => {
    return (
        <section className={s.split}>
            <div className={s.split__wrapper}>
                <div
                    className={s.split__content}
                    dangerouslySetInnerHTML={{
                        __html: input.primary.content.html,
                    }}
                ></div>
                <div
                    className={`gradient ${s.split__img}`}
                    data-side={input.primary.image_side}
                >
                    <Img
                        fluid={
                            input.primary.image.localFile.childImageSharp.fluid
                        }
                        style={{ width: `100%`, height: `100%` }}
                    />
                </div>
            </div>
        </section>
    )
}

export default SplitSection
